'use client';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { PropsWithChildren } from 'react';
const queryClient = new QueryClient();
const QueryProvider: React.FC<PropsWithChildren> = ({
  children
}) => {
  return <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-component="QueryProvider" data-sentry-source-file="QueryProvider.tsx">{children}</QueryClientProvider>;
};
export default QueryProvider;