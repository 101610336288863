import { Role, User } from '@/db/schema';
import { useOnlineUsers } from '@/stores/onlineUsers.store';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { toBlobURL } from '@ffmpeg/util';
import { type ClassValue, clsx } from 'clsx';
import { differenceInCalendarMonths, differenceInCalendarYears, differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds, differenceInWeeks } from 'date-fns';
import { User as LuciaUser } from 'lucia';
import { RefObject } from 'react';
import { twMerge } from 'tailwind-merge';

export const coinValue = 0.65;

export const coinPrice = (valueInCZK: number) => {
    return Math.ceil(valueInCZK / coinValue);
};
export const czkPrice = (valueInCZK: number) => {
    return Math.ceil(valueInCZK * coinValue);
};

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export function truncate(text: string, length: number): string {
    if (text.length <= length) {
        return text;
    }
    return text.slice(0, length) + '...';
}

export function apiUrl(path: string = '') {
    return `${process.env.NEXT_PUBLIC_API_URL}/${path}`;
}
export function contentUrl(contentId: string, contentType: 'image' | 'video', blur = false, thumb = false) {
    return `${process.env.NEXT_PUBLIC_API_URL}/content/${contentType}/${contentId}?type=${thumb ? `thumbnail` : 'video'}&blur=${String(blur)}`;
}
export function apiFiles(path: string = '') {
    return `${process.env.NEXT_PUBLIC_API_URL}/files/${path}`;
}

export function getGirlFileUrl(username: string, fileName: string | null = '', content: boolean = false) {
    return fileName ?? '';
    // return `${process.env.NEXT_PUBLIC_API_URL}/files/girls?filepath=${username}${content ? '/content/' : '/'}${fileName}`;
}

export const base64ToUint8Array = (base64: string) => {
    const padding = '='.repeat((4 - (base64.length % 4)) % 4);
    const b64 = (base64 + padding).replace(/-/g, '+').replace(/_/g, '/');

    const rawData = window.atob(b64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
};

export function formatPhoneNumberCzech(phoneNumberString: string): string | null {
    const cleaned = phoneNumberString.replace(/\D/g, '').replace('420', ''); // Remove non-digit characters
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/); // Match valid Czech phone number format
    if (match) {
        const areaCode = match[1];
        const middle = match[2];
        const last = match[3];
        return `${areaCode} ${middle} ${last}`;
    }

    return null; // Invalid input
}
export const thumbnail = (targetRef: RefObject<HTMLVideoElement> | RefObject<HTMLImageElement>, blur?: boolean): File | null => {
    let file: File | null = null;
    if (targetRef.current) {
        const current = targetRef.current;
        const isVideo = current instanceof HTMLVideoElement;
        const canvas = document.createElement('canvas');
        const width = isVideo ? current.videoWidth : current.width;
        const height = isVideo ? current.videoHeight : current.height;
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        if (ctx) {
            if (blur) ctx.filter = 'blur(10px)';
            ctx.drawImage(current, 0, 0, width, height);
            canvas.toBlob(blob => {
                if (!blob) return;
                const f = new File([blob], 'thumbnail.png', { type: 'image/png' });
                file = f;
            });
        }
    }
    return file;
};

export const formatTimeFromNow = (date: Date | string): string => {
    const d = new Date(date);
    const now = new Date();

    const seconds = differenceInSeconds(now, d);
    if (seconds < 60) {
        return `${seconds}s`;
    }

    const minutes = differenceInMinutes(now, d);
    if (minutes < 60) {
        return `${minutes}m`; // Less than an hour, show minutes
    }

    const hours = differenceInHours(now, d);
    if (hours < 24) {
        return `${hours}h`; // Less than a day, show hours
    }

    const days = differenceInDays(now, d);
    if (days < 7) {
        return `${days}d`; // Less than a week, show days
    }

    const weeks = differenceInWeeks(now, d);
    if (weeks < 5) {
        return `${weeks}t`; // 1 week or more, show weeks
    }

    const months = differenceInCalendarMonths(now, d);
    if (months < 12) {
        return `${months} měs`; // 1 week or more, show weeks
    }

    const years = differenceInCalendarYears(now, d);

    return `${years}r`;
};

export const includesEither = <T>(arr: T[], value1: T, value2: T): boolean => arr.includes(value1) || arr.includes(value2);

export function generateInviteCode(length = 8) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const loadFfmpeg = async () => {
    try {
        const ffmpegInstance = new FFmpeg();
        ffmpegInstance.on('log', ({ message }) => {
            console.log(message);
        });

        await ffmpegInstance.load({
            coreURL: await toBlobURL('/ffmpeg-core.js', 'text/javascript'),
            wasmURL: await toBlobURL('/ffmpeg-core.wasm', 'application/wasm')
        });
        return ffmpegInstance;
    } catch (err) {
        console.error('Failed to load FFmpeg:', err);
        return null;
    }
};

export const checkUserRole = (user: User | LuciaUser, role: Role[]) => {
    if (!user) return false;
    const userRoles = user.role as Role[];
    return userRoles.some(userRole => role.includes(userRole));
};

export const replaceApiUrl = (url: string) => {
    return url.replace('{API_URL}', process.env.NEXT_PUBLIC_API_URL!);
};

export const getProfilePicture = (username: string, avatar: string | null) => {
    return avatar && avatar?.length > 0 ? avatar.replace('{API_URL}', process.env.NEXT_PUBLIC_API_URL!) : `https://api.dicebear.com/9.x/thumbs/svg?seed=${username}`;
};

export const convertUrlsToLinks = (text: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|([\w-]+\.[\w-]+\.[^\s]+)/g;
    return text.replace(urlRegex, url => {
        let href = url;
        if (!url.match('^https?://')) {
            href = 'http://' + url;
        }
        return `<a href="${href}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
};

export const convertCustomLinksToHtml = (text: string) => {
    const linkRegex = /link\(([^,]+),\s*([^)]+)\)/g;
    return text.replace(linkRegex, (match, url, displayText) => {
        // Odstranění případných uvozovek z URL a zobrazovaného textu
        url = url.trim().replace(/^["']|["']$/g, '');
        displayText = displayText.trim().replace(/^["']|["']$/g, '');

        // Přidání protokolu, pokud chybí
        if (!url.match('^https?://')) {
            url = 'http://' + url;
        }

        return `<a href="${url}" target="_blank" rel="noopener noreferrer" style="color:#00a1e3">${displayText}</a>`;
    });
};

export const onlineStatusColor = (userId: string, onlineUsers: { id: string; lastSeen: any }[]) => {
    console.log(onlineUsers.find(ou => ou.id === userId));
    const ou = onlineUsers.find(ou => ou.id === userId);
    if (ou) {
        const difference = differenceInMinutes(new Date(), ou.lastSeen);
        if (difference <= 10) {
            console.log('green');
            return 'green';
        } else if (difference < 60) {
            console.log('orange');
            return 'orange';
        } else if (difference >= 60) {
            console.log('red');
            return 'red';
        }
    }
    return 'red';
};
